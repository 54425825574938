import React from "react"
import Container from "./container"

export const CONTAINER_MARGIN_LEFT =
  "pl-2 md:pl-16 lg:pl-32 xl:pl-64 2xl:pl-96 3xl:pl-112"
export const CONTAINER_MARGIN_RIGHT =
  "pr-2 md:pr-16 lg:pr-32 xl:pr-64 2xl:pr-96 3xl:pr-112"
export const CONTAINER_MARGIN = `${CONTAINER_MARGIN_LEFT} ${CONTAINER_MARGIN_RIGHT}`

type ContainerProps = {
  className?: string
  style?: any
}

const SmallContainer: React.FC<ContainerProps> = ({
  className,
  style,
  children,
}) => (
  // <div className={`container px-4 mx-auto ${className}`} style={style}>
  //   {children}
  // </div>
  // <div className={`${CONTAINER_MARGIN} ${className}`} style={style}>
  //   {children}
  // </div>

  <Container className={`${className}`} style={style}>
    <Container>{children}</Container>
  </Container>
)

SmallContainer.defaultProps = {
  className: "",
  style: {},
}

export default SmallContainer
