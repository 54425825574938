import React, { useState, useEffect, CSSProperties } from "react"
import Container from "../components/container"
import BlueIndexLink from "../components/buttons/blueindexlink"
import useSiteMetadata from "../hooks/sitemetadata"
import useContextName from "../hooks/contextname"
import { graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HideSmall from "../components/hidesmall"
import useBreakpoints from "../hooks/breakpoints"
import FlatCard from "../components/flatcard"
import BWImage from "../components/images/bwimage"
import { useSortByDate } from "../hooks/sortpublications"
import useCorePublications from "../hooks/corepublications"
import Row from "../components/row"
import { useCalEvents } from "./caleventstemplate"
import { useCourses } from "./coursestemplate"
import Day from "../components/calendar/day"
import FullDiv from "../components/fulldiv"
import useFormatStartTime from "../hooks/formatstarttime"
import BasePublication from "../components/publication/basepublication"
import SmallContainer from "../components/smallcontainer"
import AltView from "../components/altview"
import ColorLink from "../components/buttons/colorlink"
import dayjs from "dayjs"
import Abstract from "../components/abstract"
import { Carousel } from "react-responsive-carousel"
import WhiteIndexLink from "../components/buttons/whiteindexlink"
import CalEventSmall from "../components/calendar/caleventsmall"
import BaseImage from "../components/images/baseimage"
import PageLayout from "../layouts/pagelayout"

const N_EVENTS = 4
const N_PUBLICATIONS = 12

type HomeDivProps = {
  className?: string
}

const HomeDiv: React.FC<HomeDivProps> = ({ className, children }) => (
  <div className={`py-32 ${className}`}>
    <Container>{children}</Container>
  </div>
)

HomeDiv.defaultProps = {
  className: "",
}

const HomeTitle: React.FC<HomeDivProps> = ({ children, className }) => (
  <h2 className={`mb-4 ${className}`}>{children}</h2>
)

HomeTitle.defaultProps = {
  className: "",
}

type CarouselProps = {
  peopleList: Array<any>
  facultyMarkdownMap: any
  imageMap: any
}

const FacultyCarousel: React.FC<CarouselProps> = ({
  peopleList,
  facultyMarkdownMap,
  imageMap,
}) => {
  const [state, setState] = useState(0)

  const onChange = (value: number) => {
    setState(value)
  }

  const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: true,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: true,
    interval: 10000,
    transitionTime: 2000,
  })

  const { paths } = useSiteMetadata()

  const imgs = peopleList.map((person: any, index: number) => {
    let fluid

    if (person.frontmatter.id in imageMap) {
      fluid = imageMap[person.frontmatter.id]
    } else {
      fluid = imageMap["generic"]
    }

    return (
      <Link
        to={`${paths.facultyPath}/${person.frontmatter.id}`}
        key={person.frontmatter.id}
      >
        {/* <Row className="p-8 h-full hover:text-cuimc-button-blue bg-cuimc-gray trans-ani">
          <div className="w-1/4">
            <div className="w-40 h-40 overflow-hidden rounded-full bg-white mx-auto">
              <BWImage
                className="w-full h-full"
                image={getImage(fluid}
                alt={person.frontmatter.name}
              />
            </div>
            <div className="text-sm mt-2">
              <div className="font-medium">{person.frontmatter.name}</div>
              {person.frontmatter.name.includes("Riccardo") && (
                <div>Director</div>
              )}
              <div>{useContextName("default", person.titleMap).split(";")[0]}</div>
            </div>
          </div>
          <div className="text-sm w-3/4 text-left ml-16">
            <HTMLDiv o={facultyMarkdownMap[person.frontmatter.id]} />
          </div>
        </Row> */}

        <div className="w-1/4 hover:text-cuimc-button-blue  trans-ani">
          <div className="w-40 h-40 overflow-hidden rounded-full bg-white mx-auto">
            <BWImage
              className="w-full h-full"
              image={fluid}
              alt={person.frontmatter.name}
            />
          </div>
          <div className="text-sm mt-2">
            <div className="font-medium">{person.frontmatter.name}</div>
            {person.frontmatter.name.includes("Riccardo") && (
              <div>Director</div>
            )}
            <div>
              {useContextName("default", person.titleMap).split(";")[0]}
            </div>
          </div>
        </div>
      </Link>
    )
  })

  const indicatorStyles: CSSProperties = {
    background: "#fff",
    width: 8,
    height: 8,
    display: "inline-block",
    margin: "0 8px",
    borderRadius: "1000px",
  }

  return (
    <Carousel
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: "rgba(0,0,0, 0.5)" }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          )
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        )
      }}
      {...getConfigurableProps()}
    >
      {imgs}
    </Carousel>
  )
}

const SmallFacultyCarousel: React.FC<CarouselProps> = ({
  peopleList,
  facultyMarkdownMap,
  imageMap,
}) => {
  const [state, setState] = useState(0)

  const onChange = (value: number) => {
    setState(value)
  }

  const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: true,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: true,
    interval: 10000,
    transitionTime: 2000,
  })

  const { paths } = useSiteMetadata()

  const imgs = peopleList.map((person: any, index: number) => {
    let fluid

    if (person.frontmatter.id in imageMap) {
      fluid = imageMap[person.frontmatter.id]
    } else {
      fluid = imageMap["generic"]
    }

    return (
      <Link
        to={`${paths.facultyPath}/${person.frontmatter.id}`}
        key={person.frontmatter.id}
      >
        <div className="bg-cuimc-gray h-72 pt-8">
          <div className="w-32 h-32 overflow-hidden rounded-full bg-white mx-auto">
            <BWImage
              className="w-full h-full"
              image={fluid}
              alt={person.frontmatter.name}
            />
            {/* <BWImage2
                src={usePersonImageURL(person)}
                className="w-full h-full"
                alt={person.frontmatter.name}
              /> */}
          </div>
          <div className="text-sm mt-2">
            <div className="font-medium">{person.frontmatter.name}</div>
            {person.frontmatter.name.includes("Riccardo") && (
              <div>Director</div>
            )}
            <div>
              {useContextName("default", person.titleMap).split(";")[0]}
            </div>
          </div>
        </div>
      </Link>
    )
  })

  const indicatorStyles: CSSProperties = {
    background: "#fff",
    width: 8,
    height: 8,
    display: "inline-block",
    margin: "0 4px",
    borderRadius: "1000px",
  }

  return (
    <Carousel
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: "rgba(0,0,0, 0.5)" }}
              aria-label={`Selected: ${label} ${index + 1}`}
              title={`Selected: ${label} ${index + 1}`}
            />
          )
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        )
      }}
      {...getConfigurableProps()}
      className="bg-cuimc-gray"
    >
      {imgs}
    </Carousel>
  )
}

type LectureProps = {
  course: any
  section: any
  lecture: any
  paths: any
}

const Lecture: React.FC<LectureProps> = ({
  course,
  section,
  lecture,
  paths,
}) => {
  const [hover, setHover] = useState(false)

  const onMouseEnter = (e: any) => {
    setHover(true)
  }

  const onMouseLeave = (e: any) => {
    setHover(false)
  }

  const date = lecture.start

  // const day = {
  //   month: lecture.start.toLocaleString("default", {
  //     month: "short",
  //   }),
  //   day: lecture.start.toLocaleString("default", {
  //     day: "numeric",
  //   }),
  //   weekday: lecture.start.toLocaleString("default", {
  //     weekday: "short",
  //   }),
  // }

  return (
    <FlatCard
      padding="p-4"
      bg="bg-cuimc-light-gray"
      autoHide={false}
      showShadow={false}
    >
      <Row isVCentered={false}>
        <div className="w-28 min-w-28 mb-8 mr-4">
          <Day date={date} dayBgColor="bg-green-500" />
        </div>
        <Row className="w-full">
          <FullDiv>
            <div className="uppercase text-green-500">{course.title}</div>
            <div>
              <h3 className="m-0 font-medium">
                <ColorLink to={paths.coursesPath}>{lecture.title}</ColorLink>
              </h3>
            </div>
            <div>
              <h5>{lecture.lecturer}</h5>
            </div>

            <Row size="lg" className="w-full">
              <Row
                size="lg"
                w="w-1/2"
                className="text-gray-500 hover:text-default-blue trans-ani mb-2"
              >
                <div>
                  <FontAwesomeIcon
                    icon={["far", "clock"]}
                    className={`text-2xl mr-2`}
                  />
                </div>
                <div>{`${useFormatStartTime(lecture.start)}`}</div>
              </Row>
              <Row
                size="lg"
                w="w-1/2"
                className="text-gray-500 hover:text-default-blue trans-ani mb-2"
              >
                <div>
                  <FontAwesomeIcon
                    icon="map-marker-alt"
                    className={`text-2xl mr-2`}
                  />
                </div>
                <div>{lecture.location}</div>
              </Row>
            </Row>
          </FullDiv>

          <HideSmall size="lg" className="min-w-56 overflow-hidden"></HideSmall>
        </Row>
      </Row>
    </FlatCard>
  )
}

export const usePublications = (data: any) => {
  // const ret = []

  // for (const { node } of data.publications.publications.edges) {
  //   const course = node

  //   ret.push(course)
  // }

  return data.publications.edges[0].node.publications
}

type IndexTemplateProps = {
  path: string
  pageContext: any
  data: any
}

const IndexTemplate: React.FC<IndexTemplateProps> = ({
  path,
  pageContext,
  data,
}) => {
  const { director, allFaculty, facultyMarkdownMap } = pageContext

  const allCalEvents = useCalEvents(data)
  const allCourses = useCourses(data)
  const allPublications = usePublications(data)

  const [calEvents, setCalEvents] = useState<Array<any>>([])
  //const [allPublications, setAllPublications] = useState<Array<any>>([])
  const [publications, setPublications] = useState<Array<any>>([])

  const { paths, description } = useSiteMetadata()

  //const imageMap = useImageMap(data)

  const breakpoint = useBreakpoints()

  useEffect(() => {
    const now = new Date()

    // for (let calEvent of allCalEvents) {
    //   const title = calEvent.frontmatter.title
    // const start = new Date(calEvent.frontmatter.start)
    // const end = new Date(calEvent.frontmatter.end)
    // const formattedTitle = title.toLowerCase().replace(/ /g, "-")
    // const formattedDate = `${start.getFullYear()}-${useTwoDigitFormat(
    //   start.getMonth() + 1
    // )}-${useTwoDigitFormat(start.getDate())}`

    // // calEvent.date = {
    // //   year: start.getFullYear(),
    // //   month: start.getMonth() + 1,
    // //   day: start.getDate(),
    // //   start: start.getHours(),
    // // }

    // calEvent.id = `${formattedTitle}-${formattedDate}`
    // calEvent.icsFile = `/api/v1/events/ical/${calEvent.id}.ics`

    //   calEvent.start = start
    //   calEvent.end = end
    // }

    for (let course of allCourses) {
      for (let section of course.sections) {
        for (let lecture of section.lectures) {
          lecture.start = dayjs(lecture.start)
        }
      }
    }

    let eventMap = new Map<Date, Array<any>>()

    for (let calEvent of allCalEvents) {
      if (!eventMap.has(calEvent.start)) {
        eventMap.set(calEvent.start, [])
      }

      eventMap.get(calEvent.start).push(<CalEventSmall event={calEvent} />)
    }

    // for (let course of allCourses) {
    //   for (let section of course.sections) {
    //     for (let lecture of section.lectures) {
    //       if (!eventMap.has(lecture.start)) {
    //         eventMap.set(lecture.start, [])
    //       }

    //       eventMap
    //         .get(lecture.start)
    //         .push(
    //           <Lecture
    //             course={course}
    //             section={section}
    //             lecture={lecture}
    //             paths={paths}
    //           />
    //         )
    //     }
    //   }
    // }

    const ev: Array<any> = []

    for (let start of Array.from(eventMap.keys()).sort(
      (a: any, b: any) => a - b
    )) {
      for (let e of eventMap.get(start)) {
        if (start >= now) {
          ev.push(
            <li className="block w-full md:w-9/20 lg:w-3/10 2xl:w-22/100 border border-solid border-gray-200 rounded p-4 mb-8">
              {e}
            </li>
          )

          // <EventListItem index={ev.length} className="mb-8" key={ev.length}>
          //   {e}
          // </EventListItem>

          // <li className="inline-block w-full md:w-9/20 lg:w-1/4 2xl:w-1/5 mb-8 border border-solid border-gray-200 p-4 h-full mx-2">
          //   {e}
          // </li>
          //)

          if (ev.length === N_EVENTS) {
            break
          }
        }
      }

      if (ev.length === N_EVENTS) {
        break
      }
    }

    setCalEvents(ev)

    setPublications(
      useSortByDate(useCorePublications(allPublications)).slice(
        0,
        N_PUBLICATIONS
      )
    )

    //usePublications(setAllPublications, "all")
  }, [])

  // useEffect(() => {
  //   setPublications(
  //     useSortByDate(allPublications).slice(
  //       0,
  //       N_PUBLICATIONS
  //     )
  //   )
  // }, [allPublications])

  let facultyList: Array<any> = []

  for (let group of allFaculty) {
    facultyList = facultyList.concat(
      group.people.map((p: any) => {
        return p.person
      })
    )
  }

  return (
    <PageLayout title="Home">
      <AltView size="xl">
        <Container className="my-8">
          <h1 className="text-center mb-8">Mission</h1>

          <Abstract>
            <p className="h-full bg-white-90 text-justify">{description}</p>
          </Abstract>
        </Container>

        <div className="relative">
          <BaseImage
            image={data.cumcImage}
            style={{ height: "32rem" }}
            alt="ICG"
          />

          <SmallContainer
            className="absolute top-0"
            style={{ marginTop: "12rem" }}
          >
            <div className="bg-white p-12 shadow-md">
              {/* <MainCard> */}
              <h1 className="text-center mb-8">Mission</h1>

              <div className="text-justify leading-8">{description}</div>
              {/* </MainCard> */}
            </div>
          </SmallContainer>
        </div>
        {/* <>
          <div className="relative overflow-hidden">
             <Container className="absolute top-0 left-0 z-10">
              <div className=" bg-cuimc-blue-90 text-white-99 p-16 py-24">
                <h4 className="uppercase tracking-wide mb-8 border-white-99">
                  Mission
                </h4>

                <p className="text-justify">
                {description}
                </p>
              </div>
            </Container> 
            <CumcSection data={data} className="absolute left-0 top-0 pt-32" />
          </div>
        </> */}
      </AltView>

      <SmallContainer className="py-16 lg:py-32 xl:py-64 bg-gray-100">
        <Row wrap={true} className="justify-between w-full">
          <Link
            to={paths.facultyPath}
            className="block w-full md:w-9/20 xl:w-3/10 mb-8"
          >
            <div className="flex flex-col bg-cuimc-blue-90 hover:bg-cuimc-blue trans-ani justify-end h-64 text-white p-4">
              <h3>Faculty</h3>
              <p>Explore our research areas</p>
            </div>
          </Link>

          <Link
            to={paths.eventsPath}
            className="block w-full md:w-9/20 xl:w-3/10 mb-8"
          >
            <div className="flex flex-col bg-cuimc-blue-90 hover:bg-cuimc-blue trans-ani justify-end h-64 text-white p-4">
              <h3>Events</h3>
              <p>Browse upcoming events</p>
            </div>
          </Link>

          <Link
            to={paths.newsPath}
            className="block w-full md:w-9/20 xl:w-3/10 mb-8"
          >
            <div className="flex flex-col bg-cuimc-blue-90 hover:bg-cuimc-blue trans-ani justify-end h-64 text-white p-4">
              <h3>News</h3>
              <p>Recent announcements</p>
            </div>
          </Link>
        </Row>
      </SmallContainer>

      <div className="py-16 bg-cuimc-bg-blue">
        <Container>
          <Row isCentered={true} className="mb-12 pb-4 text-white">
            {/* <HideSmall>
              <FontAwesomeIcon icon={"book-open"} size="2x" className="mr-4" />
            </HideSmall> */}
            <h1 className="text-center tracking-wide">Recent Publications</h1>
          </Row>

          <ul className="flex flex-row flex-wrap justify-between items-stretch -mx-2">
            {publications.map((publication: any, index: number) => {
              return (
                <li className="block w-full md:w-9/20 xl:w-3/10 3xl:w-22/100 p-6 mb-8 bg-white">
                  <BasePublication
                    publication={publication}
                    showAbstract={false}
                    showDOI={false}
                  />
                </li>
              )
            })}
          </ul>

          <Row isCentered={true}>
            <h4>
              <WhiteIndexLink to={paths.publicationsPath}>
                View All Publications
              </WhiteIndexLink>
            </h4>

            {/* <BlueButtonLink to={paths.publicationsPath} className="px-6 py-3">
              All Publications
            </BlueButtonLink> */}
          </Row>
        </Container>
      </div>

      <Container className="py-16">
        <Row
          isCentered={true}
          className="mb-12 pb-4 border-b-5 border-solid border-cuimc-button-blue text-cuimc-button-blue"
        >
          <FontAwesomeIcon icon={"calendar-alt"} size="2x" className="mr-4" />

          <h1 className="text-center text-black tracking-wide">Events</h1>
        </Row>

        <ul className="flex flex-row flex-wrap justify-between items-stretch">
          {calEvents}
        </ul>

        <Row isCentered={true} className="mt-8">
          <h4>
            <BlueIndexLink to={paths.eventsPath}>View All Events</BlueIndexLink>
          </h4>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default IndexTemplate

export const query = graphql`
  query {
    cumcImage: file(absolutePath: { regex: "/cumc.jpg/" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    publications: allPublicationsJson(filter: { person: { eq: "all" } }) {
      edges {
        node {
          person
          publications {
            pmid
            pmcid
            doi
            title
            abstract
            authors
            authorList
            peopleList
            journal
            issue
            pages
            volume
            year
            month
            day
            url
            tagList
          }
        }
      }
    }

    calEvents: allMarkdownRemark(
      sort: { fields: frontmatter___start, order: ASC }
      filter: { fileAbsolutePath: { regex: "/events/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            location
            start
            date: start(formatString: "MMM DD, YYYY")
            day: start(formatString: "D")
            weekday: start(formatString: "ddd")
            month: start(formatString: "MMM")
            monthNum: start(formatString: "M")
            year: start(formatString: "YYYY")
            startTime: start(formatString: "h:mm a")
            end
            endTime: end(formatString: "h:mm a")
            url
            tagList
          }
          excerpt(format: HTML)
          html
        }
      }
    }

    courses: allCoursesJson {
      edges {
        node {
          id
          title
          directors
          details
          sections {
            title
            lectures {
              title
              lecturer
              start
              location
            }
          }
        }
      }
    }
  }
`
