import React, { useState } from "react"
import Row from "./row"

type AbstractProps = {
  h?: string
}

const Abstract: React.FC<AbstractProps> = ({ h, children }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <div
        className={`relative mt-2 ${
          expanded ? "overflow-y-visible" : `overflow-y-hidden ${h}`
        }`}
      >
        {children}

        {!expanded && (
          <Row
            isCentered={true}
            className="absolute bottom-0 w-full h-full cursor-pointer hover:text-blue-500 trans-ani"
            style={{
              background:
                "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255, 1) 100%)",
            }}
            onClick={() => setExpanded(!expanded)}
          >
            {/* <div className="border border-solid border-white-90 rounded px-2 py-1">
              Read More
            </div> */}
          </Row>
        )}
      </div>

      {/* <Row
        isCentered={true}
        className="w-full cursor-pointer hover:text-blue-500 trans-ani"
        onClick={() => setExpanded(!expanded)}
      >
        <div>
          <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} />
        </div>
      </Row> */}

      <Row isCentered={true} className="mt-4">
        <button
          className="bg-white border border-solid border-cuimc-button-blue px-6 py-3 text-sm rounded text-cuimc-button-blue trans-ani"
          onClick={() => setExpanded(!expanded)}
        >{`Read ${expanded ? "Less" : "More"}`}</button>
      </Row>
    </>
  )
}

Abstract.defaultProps = {
  h: "h-72",
}

export default Abstract
